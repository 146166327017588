<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <organization-selector
        :organization="product.organization.id"
        :filterable="true"
        :showAll="false"
        @organizationChanged="
          (organizationId) => {
            onOrganizationChanged(organizationId);
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('COMMON.LOCATION')}`"
      :placeholder="$t('COMMON.LOCATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
    >
      <locations-selector
        :locations="product.allowedLocations"
        :filterable="true"
        :showAll="false"
        :multiple="true"
        :organization="product.organization.id"
        @locationsChanged="
          (locations) => {
            product.allowedLocations = locations;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.allowedLocations" />

    <base-input
      :label="`${$t('COMMON.CATEGORIES')}`"
      :placeholder="$t('COMMON.CATEGORIES')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CATEGORIES)"
    >
      <base-input>
        <categories-selector
          :allowNone="false"
          :showAll="false"
          :isOnlyModels="false"
          :categories="product.categories"
          :filterOrganization="product.organization.id"
          @categoryChanged="
            (categoriesIds) => {
              product.categories = categoriesIds;
              onFormChanged();
            }
          "
        />
      </base-input>
    </base-input>
    <validation-error :errors="apiValidationErrors.allowedLocations" />

    <image-selector
      :label="$t('COMMON.PICTURE')"
      :defaultImage="product.picture"
      ressource_name="products"
      :ressource_id="product.id ? product.id : 0"
      field="picture"
      @imageChanged="
        (file_url) => {
          product.picture = file_url;
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.attachment" />

    <gallery-selector
      :label="$t('COMMON.GALLERY')"
      :defaultGallery="product.gallery"
      ressource_name="products"
      :ressource_id="product.id ? product.id : 0"
      field="gallery"
      @galleryChanged="
        (gallery_urls) => {
          product.gallery = gallery_urls;
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.attachment" />

    <base-input
      :label="`${$t('COMMON.NAME')} (*)`"
      :placeholder="$t('COMMON.NAME')"
      v-model="product.name"
      @change="onFormChanged()"
    />
    <validation-error :errors="apiValidationErrors.name" />

    <!-- <base-input>
      <el-select
        :clearable="true"
        class="select-primary pagination-select"
        v-model="product.status"
        :placeholder="$t('COMMON.STATUS')"
      >
        <el-option
          class="select-primary"
          v-for="(item, key) in PRODUCT_STATUS"
          :key="key"
          :label="$t(`PRODUCTS.STATUS_${item}`)"
          :value="item"
        >
        </el-option>
      </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.status" /> -->

    <base-input
      :label="`${$t('PRODUCTS.SKU')}`"
      :placeholder="$t('PRODUCTS.SKU')"
      v-model="product.sku"
      @change="onFormChanged()"
    />
    <validation-error :errors="apiValidationErrors.sku" />

    <base-input
      :label="`${$t('COMMON.UNIT_OF_MEASURE')} (*)`"
      :placeholder="$t('COMMON.UNIT_OF_MEASURE')"
    >
      <unit-of-measure-selector
        :allowNone="false"
        :showAll="false"
        :filterOrganization="product.organization.id"
        :unitOfMeasure="product.unitOfMeasure.id"
        @unitOfMeasureChanged="
          (unitOfMeasureId) => {
            product.unitOfMeasure.id = unitOfMeasureId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.unitOfMeasure" />

    <base-checkbox v-model="product.can_be_sold" class="mb-3">
      <span class="form-control-label">
        {{ $t("PRODUCTS.CAN_BE_SOLD") }}
      </span>
    </base-checkbox>
    <validation-error :errors="apiValidationErrors.can_be_sold" />

    <base-input
      :label="`${$t('COMMON.SELLING_PRICE')} (*)`"
      :placeholder="$t('COMMON.SELLING_PRICE')"
      v-model="product.selling_price"
      @change="onFormChanged()"
      v-if="product.can_be_sold"
    />
    <validation-error :errors="apiValidationErrors.selling_price" />

    <base-checkbox v-model="product.can_be_purchased" class="mb-3">
      <span class="form-control-label">
        {{ $t("PRODUCTS.CAN_BE_PURCHASED") }}
      </span>
    </base-checkbox>
    <validation-error :errors="apiValidationErrors.can_be_purchased" />

    <base-input
      :label="`${$t('COMMON.BUYING_PRICE')} (*)`"
      :placeholder="$t('COMMON.BUYING_PRICE')"
      v-model="product.buying_price"
      @change="onFormChanged()"
      v-if="product.can_be_purchased"
    />
    <validation-error :errors="apiValidationErrors.buying_price" />

    <base-input
      :label="$t('PRODUCTS.ORDER_UNIT_OF_MEASURE_UNIT')"
      :placeholder="$t('COMMON.ORDER_UNIT_OF_MEASURE_UNIT')"
      v-if="product.can_be_purchased"
    >
      <unit-of-measure-unit-selector
        :filterOrganization="product.organization.id"
        :filterUnitOfMeasure="product.unitOfMeasure.id"
        :unit="product.orderUnitOfMeasureUnit?.id"
        :allowNone="true"
        :showAll="false"
        @unitChanged="
          (unit) => {
            product.orderUnitOfMeasureUnit = unit;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <base-input
      :label="`${$t('COMMON.TAX_GROUPS')}`"
      :placeholder="$t('COMMON.TAX_GROUPS')"
    >
      <tax-groups-selector
        :taxGroups="product.taxGroups"
        @taxGroupsChanged="
          (taxGroups) => {
            product.taxGroups = taxGroups;
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.taxGroups" />

    <base-input
      :label="$t('COMMON.EXCERPT')"
      :placeholder="$t('COMMON.EXCERPT')"
    >
      <html-editor v-model="product.excerpt" @change="onFormChanged()">
      </html-editor>
    </base-input>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <base-input>
      <el-select
        :label="`${$t('PRODUCTS.ORIGIN')}`"
        :placeholder="$t('PRODUCTS.ORIGIN')"
        v-model="product.origin"
        @change="onFormChanged()"
      >
        <el-option
          :value="PRODUCT_ORIGIN_QUEBEC"
          :label="$t('PRODUCTS.PRODUCT_ORIGIN_QUEBEC')"
        />
        <el-option
          :value="PRODUCT_ORIGIN_CANADA"
          :label="$t('PRODUCTS.PRODUCT_ORIGIN_CANADA')"
        />
        <el-option
          :value="PRODUCT_ORIGIN_OTHER"
          :label="$t('PRODUCTS.PRODUCT_ORIGIN_OTHER')"
        />
        <el-option
          :value="PRODUCT_ORIGIN_UNKNOWN"
          :label="$t('PRODUCTS.PRODUCT_ORIGIN_UNKNOWN')"
        />
      </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.origin" />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          product.id ? $t("PRODUCTS.EDIT_PRODUCT") : $t("PRODUCTS.ADD_PRODUCT")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Option, Select } from "element-ui";
import { mapGetters } from "vuex";
import swal from "sweetalert2";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import TaxGroupsSelector from "@/components/TaxGroupsSelector.vue";
import UnitOfMeasureSelector from "@/components/UnitOfMeasureSelector.vue";
import models from "@/constants/models";
import {
  PRODUCT_ORIGIN_QUEBEC,
  PRODUCT_ORIGIN_CANADA,
  PRODUCT_ORIGIN_OTHER,
  PRODUCT_ORIGIN_UNKNOWN,
} from "@/constants/products";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import ImageSelector from "@/components/ImageSelector.vue";
import GallerySelector from "@/components/GallerySelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import CategoriesSelector from "@/components/CategoriesSelector.vue";
import { PRODUCT_STATUS } from "@/constants/products";
import UnitOfMeasureUnitSelector from "@/components/UnitOfMeasureUnitSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    OrganizationSelector,
    ImageSelector,
    GallerySelector,
    LocationsSelector,
    CategoriesSelector,
    TaxGroupsSelector,
    UnitOfMeasureSelector,
    UnitOfMeasureUnitSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["productData", "formErrors", "loading"],

  data() {
    return {
      product: null,
      PRODUCT_STATUS,
      permissions: [],
      permissionsModel: {},
      models: models,
      PRODUCT_ORIGIN_QUEBEC,
      PRODUCT_ORIGIN_CANADA,
      PRODUCT_ORIGIN_OTHER,
      PRODUCT_ORIGIN_UNKNOWN,
      console,
    };
  },

  computed: {
    ...mapGetters({
      organizationConfig: "organizationOptions/organizationConfig",
    }),
  },

  created() {
    let productData = cloneDeep(this.productData);
    productData = this.$fillUserOrganizationData(productData);
    if (!productData.id) {
      productData.taxGroups = this.organizationConfig.DEFAULT_TAXES_GROUP;
    }
    this.product = productData;
  },

  methods: {
    async handleSubmit() {
      let productData = cloneDeep(this.product);
      productData = this.$fillUserOrganizationData(productData);
      if (productData.orderUnitOfMeasureUnit) {
        if (!productData.orderUnitOfMeasureUnit.id) {
          delete productData.orderUnitOfMeasureUnit;
        }
      }
      this.$emit("productSubmitted", productData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    async onOrganizationChanged(organizationId) {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      await this.$store.dispatch(
        "organizationOptions/getOrganizationConfig",
        organizationId
      );
      let productData = cloneDeep(this.productData);
      productData = this.$fillUserOrganizationData(productData);
      productData.organization.id = organizationId;
      productData.taxGroups = this.organizationConfig.DEFAULT_TAXES_GROUP;
      this.product = productData;
      swal.close();
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    productData(productData) {
      if (productData) {
        this.product = {
          ...this.product,
          ...cloneDeep(productData),
        };
      }
    },
  },
};
</script>
