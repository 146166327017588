import { STATUS_ACTIVE } from "@/constants/products";

export default {
  type: "products",
  name: null,
  sku: null,
  status: STATUS_ACTIVE,
  excerpt: "",
  buying_price: 0,
  selling_price: 0,
  picture: null,
  gallery: [],
  taxGroups: [],
  can_be_sold: false,
  can_be_purchased: false,
  relationshipNames: [
    "organization",
    "allowedLocations",
    "categories",
    "taxGroups",
    "unitOfMeasure",
    "orderUnitOfMeasureUnit",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  unitOfMeasure: {
    type: "unit-of-measures",
    id: null,
  },
  orderUnitOfMeasureUnit: {
    type: "unit-of-measure-units",
    id: null,
  },
  allowedLocations: [],
  categories: [],
};
